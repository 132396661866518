<template>
  <div class="content">
    <div class="describe">
      <div class="describeTitle">一指存云,互惠三端</div>
      <p class="info">
        全量影像数据保存在云端，支持下载查阅，便于携带、转诊。影像后处理技术可以更好的显示数据和诊断信息，为医生提供立体的显示手段和定量分析工具
      </p>
    </div>
    <div class="totleDicom">
      <div class="title">全量DICOM影像源文件</div>
      <p class="protocol">DICOM3.0国际标准</p>
      <div class="items">
        <div class="item">
          <el-image
            class="itemImg"
            fit="cover"
            :src="
              require('@/assets/images/phone/product/xmycp_yyx_zdjb@2x.png')
            "
          ></el-image>
          <p>诊断级别，无损图像</p>
        </div>
        <div class="item">
          <el-image
            class="itemImg"
            fit="cover"
            :src="
              require('@/assets/images/phone/product/xmycp_yyx_ydcc@2x.png')
            "
          ></el-image>
          <p>云端存储，长期保存</p>
        </div>
      </div>

      <el-image
        fit="cover"
        class="totalDicomImg"
        :src="require('@/assets/images/phone/product/xmycp_yyx_yxtux@2x_.png')"
      />
    </div>
    <div class="container productFunctionContainer">
      <div class="productFunction">
        <div>功能特点</div>
        <h4>影像存储在云端，在云端对图像处理和分析</h4>
        <div class="functionCards">
          <div class="card">
            <el-image
              fit="cover"
              :src="
                require('@/assets/images/phone/product/xmycp_yyx_yxdy@2x.png')
              "
              class="icon"
            ></el-image>
            <p class="cardTitle">影像调阅</p>
            <p class="cardDesc">PACS全量图像、无损压缩、秒级呈现</p>
          </div>
          <div class="card">
            <el-image
              fit="cover"
              :src="
                require('@/assets/images/phone/product/xmycp_yyx_txfz@2x.png')
              "
              class="icon"
            ></el-image>
            <p class="cardTitle">图像辅助处理</p>
            <p class="cardDesc">
              ·MPR、CPR、MIP、SSD、VR、VE 三维图像处理·三维裁剪切割，一键去床·3D
              MPR浏览、PEPTCT融合、三维MPR融合
            </p>
          </div>
          <div class="card">
            <el-image
              fit="cover"
              :src="
                require('@/assets/images/phone/product/xmycp_yyx_lcyy@2x.png')
              "
              class="icon"
            ></el-image>
            <p class="cardTitle">临床应用功能</p>
            <p class="cardDesc">
              影像智能分析、三维重建、高密度投影、一键去骨、虚拟手术、3D打印、实例教学等
            </p>
          </div>
          <div class="card" style="border: none">
            <div class="icon"></div>
            <p class="cardTitle"></p>
            <p class="cardDesc"></p>
          </div>
        </div>
      </div>
    </div>
    <div class="container three-dContainer">
      <div class="three-dTitle">三维重建</div>
      <p class="three-dInfo">
        三维重建技术可以更好的显示数据和诊断信息，为医生提供立体的显示手段和定量分析工具
      </p>
      <div class="three-dImage">
        <el-image
          fit="cover"
          :src="require('@/assets/images/web/product/three-d.gif')"
        />
      </div>
    </div>
    <div class="container cloudChaoshengContainer">
      <div class="cloudChaoshengDescTitle">数字云超声系统</div>
      <div class="cloudChaoshengDescInfo">
        患者超声图像数据以无损图像或者动态视频的方式存储在云端，患者可通过手机随时调阅。
      </div>
      <div class="cloudChaoshengMeaning">
        <el-image
          fit="cover"
          class="cloudChaoshengMeaningImg"
          :src="require('@/assets/images/phone/product/xmycp_yyx_jlsm@2x_.png')"
        ></el-image>
        <div class="cloudChaoshengMeaningTitle">记录生命的奇迹</div>
        <p class="cloudChaoshengMeaningInfo">
          对于孕期胎儿生长记录、宫内发育监测，有着特别的意义。其余超声检查也能根据医院的采集情况无损呈现在患者手机，长久保存。
        </p>
      </div>
      <div class="cloudChaoshengMeaning">
        <el-image
          fit="cover"
          class="cloudChaoshengMeaningImg"
          :src="
            require('@/assets/images/phone/product/xmycp_yyx_ycsyxjz@2x_.png')
          "
        ></el-image>
        <div class="cloudChaoshengMeaningTitle">云超声的医学价值</div>
        <p class="cloudChaoshengMeaningInfo">
          医生可通过云超声进行图像查询、诊断报告调阅、图像诊断、图像分析等。
        </p>
      </div>
      <el-image
        fit="cover"
        class="cloudChaoshengImg"
        :src="require('@/assets/images/phone/product/ycs_st@3x_.png')"
      ></el-image>
    </div>
    <div class="lookDicom">
      <div class="lookDicomTitle">影像云端阅片服务</div>
      <p class="lookDicomInfo">
        影像阅片可以实现全科影像秒级处理分析，满足多种智能终端设备的快速调阅、随时随地移动阅片和无胶片影像等应用需求，
        并为影像大数据、AI辅助阅片等提供了基础条件。
      </p>
      <div class="lookDicomImg"></div>
    </div>
    <div class="container cloudPacsContainer">
      <div class="cloudPacsDesc">
        <el-image
          class="cloudPacsDescImg"
          fit="cover"
          :src="
            require('@/assets/images/phone/product/pacs@3x.png')
          "
        ></el-image>
        <div class="cloudPacsDescInfo">
          <div class="cloudPacsDescTitle">全流程云PACS影像业务</div>
          <p class="cloudPacsDescTitleText">
            基于影像云平台为医院提供云PACS解决方案，向影像科医生提供从预约登记、排队叫号、拍片检查、阅片诊断的全流程影像业务应用，以及影像后处理和质控管理服务。
          </p>
        </div>
      </div>
      <div class="cloudPacsFlow">
        <el-image
          fit="cover"
          :src="require('@/assets/images/phone/product/lct@3x_.png')"
        ></el-image>
      </div>
    </div>
    <div class="container characteristicContainer">
      <div class="characteristicTitle">产品特点</div>
      <p class="characteristicDesc">
        云影像是对电子胶片的云化存储，通过将患者的影像电子胶片和影像诊断报告进行云存储，由医疗机构通过短信、微信等方式向患者提供电子链接或二维码信息，患者即可通过手机查看影像胶片和诊断报告。
      </p>
      <div class="characteristicCards">
        <div class="characteristicCard">
          <div class="characteristicCardImg">
            <img
              :src="require('@/assets/images/phone/product/fbzd@3x_.png')"
              alt=""
            />
          </div>
          <div class="characteristicCardInfo">
            <div class="characteristicCardTitle">方便诊断</div>
            <p class="characteristicCardDesc">
              医生则通过云胶片可以进行影像查询、影像档案、
              影像归档、诊断报告调阅、影像分析等。
            </p>
          </div>
        </div>
        <div class="characteristicCard">
          <div class="characteristicCardImg">
            <img
              :src="require('@/assets/images/phone/product/jycb@3x_.png')"
              alt=""
            />
          </div>
          <div class="characteristicCardInfo">
            <div class="characteristicCardTitle">节约成本</div>
            <p class="characteristicCardDesc">
              云胶片可以为患者节省胶片打印、领取的金钱和时间成本，方便携带和共享。
            </p>
          </div>
        </div>
        <div class="characteristicCard">
          <div class="characteristicCardImg">
            <img
              :src="require('@/assets/images/phone/product/tsxl@3x_.png')"
              alt=""
            />
          </div>
          <div class="characteristicCardInfo">
            <div class="characteristicCardTitle">提升效率</div>
            <p class="characteristicCardDesc">
              帮助医疗机构降低胶片的打印成本和硬件成本，提升医患沟通效率。
            </p>
          </div>
        </div>
        <div class="characteristicCard">
          <div class="characteristicCardImg">
            <img
              :src="require('@/assets/images/phone/product/sxgy@3x_.png')"
              alt=""
            />
          </div>
          <div class="characteristicCardInfo">
            <div class="characteristicCardTitle">实现共赢</div>
            <p class="characteristicCardDesc">
              给政府“双向转诊、分级诊疗”政策的推进提供基础，实现医患政府“三方共赢”。
            </p>
          </div>
        </div>
        <div class="characteristicCard">
          <div class="characteristicCardImg">
            <img
              :src="require('@/assets/images/phone/product/gnff@3x_.png')"
              alt=""
            />
          </div>
          <div class="characteristicCardInfo">
            <div class="characteristicCardTitle">功能丰富</div>
            <p class="characteristicCardDesc">
              指云端医疗提供影像解读、词条检验等特色医疗服务，并可根据需求定制解决方案。
            </p>
          </div>
        </div>
        <div class="characteristicCard">
          <div class="characteristicCardImg">
            <img
              :src="require('@/assets/images/phone/product/xnwd@3x_.png')"
              alt=""
            />
          </div>
          <div class="characteristicCardInfo">
            <div class="characteristicCardTitle">性能稳定</div>
            <p class="characteristicCardDesc">
              指云端技术团队持续开发、维护与创新，致力于高品质服务基层医院和患者。
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  }
}
</script>

<style lang="less" scoped>
.content {
  .container {
    width: 100%;
    display: inline-block;
  }
  .mainTitle {
    text-align: center;
    font-family: 'PingFang SC';
    margin: 0 auto;
    margin-top: 100px;
    color: #29262a;
    font-weight: bold;
    font-size: 49px;
  }
  .subtitle {
    text-align: center;
    font-size: 18px;
    font-family: AngsanaUPC;
    font-weight: 400;
    color: #afb4bc;
    margin: 0 auto;
    margin-top: 33px;
    letter-spacing: 7px;
  }
  .describe {
    overflow:hidden;
    margin: 0 auto;
    margin-top: 55px;
    width: 666px;
    height: 455px;
    background: url('../../../../assets/images/phone/product/xmycp_yyx_yzcy@2x_.png')
      no-repeat;
    background-size: 100% auto;
    .describeTitle {
      letter-spacing: 2px;
      text-align: center;
      display: block;
      margin: 0 auto;
      margin-top: 123px;
      font-size: 42px;
      font-family: PingFang SC;
      font-weight: 800;
      color: #ffffff;
    }
    .info {
      color: white;
      margin: 0 auto;
      margin-top: 51px;
      width: 563px;
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      line-height: 39px;
      letter-spacing: 1px;
    }
  }
  .totleDicom {
    overflow: hidden;
    width: 666px;
    height: 1052px;
    margin: 0 auto;
    .title {
      width: fit-content;
      font-size: 42px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #0d1218;
      margin: 0 auto;
      margin-top: 82px;
    }
    .protocol {
      width: fit-content;
      margin: 0 auto;
      margin-top: 39px;
      font-size: 26px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #2d292e;
    }
    .items {
      width: 564px;
      margin: 0 auto;
      margin-top: 78px;
      display: flex;
      flex-direction: row;
      .item {
        &:last-child {
          margin-right: 0px;
        }
        width: 242px;
        display: flex;
        margin-right: 80px;
        flex-direction: column;
        align-items: center;
        .itemImg {
          width: 112px;
          height: 112px;
          z-index: -1;
        }
        p {
          font-size: 24px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #454246;
          margin-top: 36px;
        }
      }
    }
    .totalDicomImg {
      display: block;
      width: 508px;
      height: 500px;
      margin: 0 auto;
      margin-top: 66px;
      z-index: -1;
    }
  }
  .productFunctionContainer {
    width: 100%;
    height: 1544px;
    background: #f9f9f9;
    margin-top: 85px;
    display: inline-block;
    .productFunction {
      overflow: hidden;
      height: 100%;
      width: 688px;
      margin: 0 auto;
      div {
        width: fit-content;
        margin: 0 auto;
        margin-top: 73px;
        font-size: 42px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #0d1218;
      }
      h4 {
        width: fit-content;
        margin: 0 auto;
        margin-top: 39px;
        letter-spacing: 3px;
        margin-bottom: 47px;
        font-size: 26px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #2d292e;
      }
      .functionCards {
        width: 688px;
        height: 581px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: start;
        .card {
          &:first-child {
            .cardDesc {
              width: 234px;
              line-height: 48px;
              margin-top: 27px;
              text-align: center;
            }
          }
          &:last-child {
            box-shadow: none;
            background: transparent;
          }
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 320px;
          height: 580px;
          background: white;
          box-shadow: 0px 7px 38px 0px rgba(12, 41, 72, 0.19);
          border-radius: 10px;
          margin-top: 24px;
          .icon {
            width: 168px;
            height: 168px;
            margin: 0 auto;
            margin-top: 64px;
          }
          .cardTitle {
            letter-spacing: 5px;
            margin: 0 auto;
            margin-top: 55px;
            font-size: 30px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #0d1218;
          }
          .cardDesc {
            margin: 0 auto;
            text-align: justify;
            width: 251px;
            letter-spacing: 2px;
            margin-top: 34px;
            font-size: 24px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #0d1218;
            line-height: 33px;
          }
        }
      }
    }
  }
  .three-dContainer {
    height: 720px;
    background: url('../../../../assets/images/phone/product/xmycp_yyx_bj2@2x (3)_.png')
      no-repeat;
    background-size: 100% auto;
    display: inline-block;
    .three-dImage {
      margin: 0 auto;
      margin-top: 100px;
      width: 586px;
      height: 313px;
      background: url('../../../../assets/images/phone/product/xmycp_yyx_bj2_sp@2x_.png');
      background-size: 100% 100%;
    }
    .three-dTitle {
      width: fit-content;
      font-size: 40px;
      font-family: FZZongYi-M05S;
      font-weight: bold;
      color: #ffffff;
      margin: 0 auto;
      margin-top: 87px;
    }
    .three-dInfo {
      width: 623px;
      margin: 0 auto;
      margin-top: 45px;
      text-align: center;
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #c7c5c5;
      line-height: 46px;
    }
  }
  .cloudChaoshengContainer {
    display: inline-block;
    height: 2549px;
    background: url('../../../../assets/images/phone/product/xmycp_yyx_bj3@3x_.png');
    background-size: 100% auto;
    .cloudChaoshengDescTitle {
      width: fit-content;
      font-size: 40px;
      font-family: FZZongYi-M05S;
      font-weight: bold;
      color: #ffffff;
      margin: 0 auto;
      margin-top: 84px;
    }
    .cloudChaoshengDescInfo {
      width: 625px;
      margin: 0 auto;
      margin-top: 30px;
      font-size: 24px;
      text-align: center;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #c7c5c5;
      line-height: 50px;
    }
    .cloudChaoshengMeaning {
      overflow: hidden;
      width: 630px;
      height: 700px;
      background: #ffffff;
      border-radius: 10px;
      margin: 0 auto;
      margin-top: 60px;
      .cloudChaoshengMeaningImg {
        display: block;
        width: 565px;
        height: 338px;
        margin: 0 auto;
        margin-top: 38px;
      }
      .cloudChaoshengMeaningTitle {
        font-size: 36px;
        font-family: PingFang SC;
        font-weight: 800;
        color: #0d1218;
        margin-left: 30px;
        margin-top: 52px;
      }
      .cloudChaoshengMeaningInfo {
        width: 558px;
        margin: 0 auto;
        margin-top: 36px;
        font-size: 28px;
        font-family: PingFang SC;
        color: #2d292e;
        line-height: 43px;
        letter-spacing: 3px;
      }
    }
    .cloudChaoshengImg {
      display: block;
      width: 607px;
      height: 592px;
      margin: 0 auto;
      margin-top: 73px;
    }
  }
  .lookDicom {
    width: 100%;
    height: 853px;
    display: inline-block;
    margin: 0 auto;
    .lookDicomTitle {
      text-align: center;
      margin-top: 76px;
      font-size: 42px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #0d1218;
    }
    .lookDicomInfo {
      text-align: center;
      width: 660px;
      margin: 0 auto;
      margin-top: 38px;
      font-size: 28px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #2d292e;
      line-height: 47px;
      letter-spacing: 2.5px;
    }
    .lookDicomImg {
      margin: 0 auto;
      margin-top: 80px;
      width: 574px;
      height: 345px;
      background: url('../../../../assets/images/phone/product/yxypfw@3x_.png')
        no-repeat;
      background-size: 100% 100%;
    }
  }
  .cloudPacsContainer {
    display: inline-block;
    height: 2270px;
    background: url('../../../../assets/images/web/product/5751366d6ee9d_1024.png')
      no-repeat;
    background-size: cover;
    .cloudPacsDesc {
      overflow: hidden;
      width: 630px;
      border-radius: 10px;
      margin: 0 auto;
      margin-top: 70px;
       background-color: #fff;
      .cloudPacsDescImg {
        width: 576px;
        height: 381px;
        margin: 0 auto;
        display: block;
        margin-top: 41px;
        background-color: red;
      }
      .cloudPacsDescInfo {
       
        width: 576px;
        margin: 0 auto;
        margin-top: 53px;
      }
      .cloudPacsDescTitle {
        font-size: 36px;
        font-family: PingFang SC;
        font-weight: 800;
        color: #0d1218;
      }
      .cloudPacsDescTitleText {
        margin-top: 39px;
        font-size: 26px;
        font-family: PingFang SC;
        color: #2d292e;
        line-height: 45px;
        text-align: justify;
      }
    }
    .cloudPacsFlow {
      width: 520px;
      height: 1296px;
      margin: 0 auto;
      margin-top: 66px;
    }
  }
  .characteristicContainer {
    display: inline-block;
    height: 2050px;
    .characteristicTitle {
      text-align: center;
      font-size: 42px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #0d1218;
      margin-top: 58px;
    }
    .characteristicDesc {
      padding-left: 42px;
      padding-right: 42px;
      font-size: 26px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #2d292e;
      line-height: 47px;
      margin-top: 47px;
      letter-spacing: 2px;
    }
    .characteristicCards {
      width: 699px;
      height: 767px;
      margin: 0 auto;
      margin-top: 60px;
      .characteristicCard {
        box-shadow: 0px 7px 38px 0px rgba(12, 41, 72, 0.19);
        border-radius: 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        width: 699px;
        height: 242px;
        margin-top: 28px;
        .characteristicCardImg {
          width: 179px;
          height: 179px;
          margin-left: 27px;
          margin-right: 21px;
          img {
            width: 179px;
            height: 179px;
          }
        }
        .characteristicCardInfo {
          flex: 1;
          height: 179px;
          margin-right: 28px;
          .characteristicCardTitle {
            font-size: 32px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #3c3c3c;
          }
          .characteristicCardDesc {
            margin-top: 31px;
            font-size: 26px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #999999;
            line-height: 38px;
          }
        }
      }
    }
  }
}
</style>
